@import url("https://fonts.googleapis.com/css2?family=Lora:ital,wght@0,400..700;1,400..700&family=Mukta:wght@200;300;400;500;600;700;800&family=Outfit:wght@100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lora", "Arial", sans-serif;
}

:root {
  --color-primary: #0e3b65;
  --color-secondary: #3c3883;
  --color-tertiary: #3c38831f;
  --color-background: #ffffff;
  --color-gray: #aaa;
}

body {
  background-color: var(--color-background);
  color: var(--color-primary);
  line-height: 1.5;
}

@layer base {
  h1 {
    font-size: 20px;
    line-height: 1.4;
    font-weight: lighter;
    font-family: "Lora", "Arial", sans-serif;

    @screen lg {
      font-size: 24px !important;
      line-height: 1.4 !important;
    }

    @screen lg {
      font-size: 30px !important;
      line-height: 1.7 !important;
    }
  }

  h2 {
    font-size: 14px;
    line-height: 1.4;
    font-family: "Mukta", "Arial", sans-serif;
    letter-spacing: 3px;
    text-transform: uppercase;
  }

  h3 {
    font-size: 13px;
    line-height: 1.8em;
    text-align: justify;
    font-family: "Lora", "Arial", sans-serif;
  }

  h4 {
    font-size: 17px;
    line-height: 1.4em;
    text-align: center;
    font-family: "Lora", "Arial", sans-serif;
  }

  h5 {
    user-select: none;
    font-size: 15px;
    font-style: italic;
    line-height: 1.7;
    font-weight: lighter;
    text-align: center;
    font-family: "Lora", "Arial", sans-serif;
  }

  h6 {
    font-size: 16px;
    text-align: center;
    font-family: "Lora", "Arial", sans-serif;
  }
}
